<template>
  <div>
    <VueHtml2pdf
      :show-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1200"
      :filename="filename"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="letter"
      pdf-orientation="portrait"
      pdf-content-width="820px"
      @beforeDownload="beforeDownload($event)"
      ref="previewImage">
      <div slot="pdf-content">
        <div
          v-for="(image, index) in images"
          :key="image.id"
          class="img_to_pdf_images"
          :class="{
            'html2pdf__page-break':
              images.length > 0 && index + 1 !== images.length,
          }">
          <section class="pdf-item mt-5">
            <img class="img-fluid" :src="image.url" alt="" />
          </section>
        </div>
      </div>
    </VueHtml2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "ImagesToPdf",
  props: {
    filename: {
      type: String,
      required: true,
    },
    images: {
      type: Array,
      required: true,
    },
  },
  components: {
    VueHtml2pdf,
  },
  methods: {
    async beforeDownload() {
      this.$nextTick(() => {
        this.$emit("updateKey");
      });
    },
  },
};
</script>

<style>
.img_to_pdf_images {
  max-height: 600px;
  max-width: 75%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img_to_pdf_images img {
  max-height: 600px;
}
</style>
